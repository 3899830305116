.work-in-progress-page {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.work-in-progress {
  font-family: system-ui;
  font-size: 50px;
  font-weight: 700;
  letter-spacing: 5px;
  text-shadow: 1px 1px #47db86;
  color: #00ab5e;
}

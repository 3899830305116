.analytics-container {
  display: flex;
  gap: 5px;
  padding: 10px;
  /* there is a minimum width for the graph
       when not in mobile -> therefore can overflow-x */
  overflow-x: scroll;
  /* overflow-y: hidden; */

  /* @media (width < 576px) {
    overflow-y: scroll;
  } */
}

.analytics-filter-card {
  flex: 0 1 20%;
  min-width: 200px;
  max-height: 90vh;

  @media (width < 1031px) {
    min-width: 100%;
  }
}

.analytics-content-card {
  flex: 0 0 80%;
  /* max-height: 100vh; */

  @media (width < 1031px) {
    flex: 1 1 100%;
    min-height: 100%;
  }
}
.analytics-content-card-title {
  display: flex;
  justify-content: space-between;
}

.analytics-picker-type-segmented .ant-segmented-item {
  width: 100%;
}

.power-energy-card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  padding: 10px 0;
}

.power-energy-content {
  display: flex;
  flex-direction: column;
  margin: 10px;

  width: auto;
  height: 95%;
}

.power-title,
.energy-title {
  flex: 26px;
}

.power-energy-content > * {
  margin: 2px;
}

.power-title,
.energy-title {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.power-title {
  display: flex;
  flex-direction: column;
  width: 260px;
}

/* .power-content, .energy-content{
    flex: 1 1 40%;
    width: auto;
} */

.graph-container {
  width: auto;
  height: 100%;
}

.power-energy-content .ant-card:not(.ant-card-bordered) {
  box-shadow: none;
}

.power-energy-loading-card {
  width: 50%;

  @media (width < 576px) {
    width: 100%;
  }
}

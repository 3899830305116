/*
refers to https://www.youtube.com/watch?v=H04P5YXVssE&t=1344s for absolute positioning
*/


.weather-card-content{
    /* setup variables */
    --padding-1: 0.5rem;
    --padding-2: 1rem;
    --font-size-1: 14px;
    --font-size-2: 16px;
    --font-size-3: 32px;
}


.weather-background{
    width: 100%;
    height: inherit;
    border-radius: 8px; /* to match default border radius of antd Card component */
    object-fit: none;
    /* !!! IMPORTANT 
        make it visible here, 
        hide in dashboard to fill up the entire card
    */
    overflow: visible; 
}

.weather-card-content span {
    /* !!! IMPORTANT    
       make everything (except video) to follow 
       absolute positioning
    */
    position: absolute;
    font-weight: 600;
    color: white;
}

.weather-location {
    /* position on top left */
    left: 0;
    font-size: var(--font-size-2);
    padding-top: var(--padding-1);
    padding-left: var(--padding-2);
}

.weather-time, .weather-weather,
.weather-solar-irradiance, .weather-performance-ratio {
    font-size: var(--font-size-1);
}

.weather-time {
    /* position on top left
       below loacation
    */
    left: 0;
    top: var(--font-size-2); /* !!! based on weather-location font-size */

    padding-top: var(--padding-2); /* !!! to accomodate weather-location top padding */
    padding-left: var(--padding-2);
}

.weather-temperature{
    /* position on top right */
    right: 0;
    font-size: var(--font-size-3);

    padding-top: var(--padding-1); 
    padding-right: var(--padding-2);
}

.weather-weather{
    /* position on bottom left */
    left:0;
    bottom: 0;

    padding-left: var(--padding-2);
    padding-bottom: var(--padding-1);
}

.weather-solar-irradiance{
    /* position on botom right
       on top of performance ratio
    */
    right: 0;
    bottom: var(--font-size-1); /* !!! based on weather-performance-ratio font-size */

    padding-right: var(--padding-2);
    padding-bottom: var(--padding-2);
}

.weather-performance-ratio{
    /* position on bottom right */
    right: 0;
    bottom: 0;

    padding-right: var(--padding-2);
    padding-bottom: var(--padding-1);
}
.summary-content-container {
  width: auto;
  height: 100%;

  display: flex;
  flex-direction: column;

  margin: 10px;
  gap: 15px;
}

.summary-content-segmented {
  margin: 10px 10px 0 10px;
}

.summary-content-detail {
  width: auto;
  display: flex;
  align-items: center;
}

.summary-content-percentage {
  margin-left: auto;
}

.summary-content-content-container .ant-tag {
  width: 20px;
  height: 20px;
}

/* .summary-content-content-container .ant-progress-inner{
    border-radius: 5px !important;
} */

.summary-content-value {
  margin-left: 5px;
}

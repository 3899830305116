.alarm-card {
  margin-left: 10px;
  margin-right: 10px;
}

.alarm-card-title {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 10px;
  padding-bottom: 10px;
}

.alarm-col {
  display: flex;
  flex-direction: column;

  height: 100%;
  gap: 30px;
}

.alarm-row {
  flex: 1 0 40%;

  display: flex;
  flex-wrap: wrap;

  gap: 30px;
}

.alarm-row > :nth-child(1) {
  flex: 1 1 20%;
  min-width: 300px;
  max-height: 200px;
}
.alarm-row > :nth-child(2) {
  flex: 1 1 20%;
  min-width: 200px;
}

@media (width<870px) {
  /* hide the divider */
  .alarm-row > :nth-child(3) {
    display: none;
  }
}

.alarm-row > :nth-child(4) {
  flex: 1 1 40%;
  min-width: 300px;
}

.alarm-content {
  width: 100%;
  height: 100%;
}

.alarm-display-title {
  font-size: 16px;
  font-weight: 600;
}

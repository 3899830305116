.energy-recharge
  .ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container {
  border-bottom: 1px solid #bdbdbd;
  border-right: 1px solid #bdbdbd;
}

.energy-recharge .ant-table-cell {
  border-top: 1px solid #bdbdbd;
  border-bottom: 1px solid #bdbdbd;
  border-left: 1px solid #bdbdbd;
  border-right: 1px solid #bdbdbd;
  border-inline-end: 1px solid #bdbdbd;
}

:root {
  --text-color-light: rgba(0, 0, 58, 0.6);
  --text-color-dark: "#ffffff";
}

.management-col {
  width: auto;
  height: 100%;
  display: grid;
  grid-template-columns: 1;
  padding-left: 20px;
  margin-bottom: 10px;

  gap: var(--biggap);
}

.management-row {
  display: flex;
  gap: var(--biggap);
  flex-wrap: wrap;
  height: 80%;
}

.input-box {
  flex: 1 1 50%;

  min-width: 300px;
  min-height: none;
  height: fit-content;
  padding: 0px 0px 0px 0px;

  display: flex;
  flex-direction: column;
  gap: var(--smallgap);
}

.ant-tabs-tab-btn {
  color: var(--text-color) !important;
}

.statistic-graph-content{
    width: auto;
    height: fit-content;

    display: flex;
    flex-direction: column;

}

.statistic-graph-detail{
    display: flex;
    flex-wrap: wrap;

    align-items: flex-end;
    padding-left: 3px;
}

.statistic-graph-detail span{
    padding-left: 5px;
    padding-right: 5px;
    height: fit-content;
}

.statistic-graph-value, .statistic-graph-unit{
    font-size: 24px;
    font-weight:600;
}

.statistic-graph-change{
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 0.4em;
}
.statistic-graph-title {
    flex: 0 0 100%;
    font-size: 14px;
    font-weight: 600;
    color: gray;
}

.statistic-graph-chart {
    width: auto;
    height: 75px;
}
.circle {
  width: 15px;
  height: 15px;
  border-radius: 50px;
}

.inverter-info-card {
  flex: 1;
  min-width: 300px;
  max-width: 300px;
  /* max-width: 100%; */
  margin-right: 10px;
  margin-bottom: 10px;
}

.inverter-info-card-title {
  display: flex;
  align-items: center;
  gap: 5px;
}

.inverter-content-container {
  display: flex;
  flex-wrap: wrap;
  /* padding: 5px 15px; */
}

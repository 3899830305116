.system-info-card-title {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.system-info-card-title-select {
  display: flex;
  align-items: center;
  justify-content: center;
}

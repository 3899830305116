:root {
  --gap-size: 20px;
}

.details-form-content {
  display: flex;
  width: 100%;
  gap: var(--gap-size);
}

.form-section {
  width: 50%;
}

@media (max-width: 1080px) {
  .details-form-content {
    flex-wrap: wrap;
  }

  .form-section {
    width: 100%;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f3f3f3;
  overflow: hidden;
  @media (width< 1031px) {
    overflow: visible;
  }
}

/*
    CUSTOMIZE Tab Bar
*/
/* selected tab */
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: rgba(0, 0, 58, 0.6);
}
/* not selected tab */
.ant-tabs-tab .ant-tabs-tab-btn {
  color: rgba(0, 0, 58, 0.5);
}
/* underline of selected tab */
.ant-tabs .ant-tabs-ink-bar {
  background: rgba(0, 0, 58, 0.6);
}

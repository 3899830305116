.figure {
  font-weight: bold;
  font-size: 30px;
}

.text {
  font-weight: bold;
  font-size: 16px;
  margin: 7px 3px;
}

.figure-and-text {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: -10px;
  justify-content: center;
  flex-shrink: 0;
}

.flexbox-container-row {
  display: flex;
  flex-direction: row;
}

.flexbox-container-col {
  display: flex;
  flex-direction: column;
}

.wrap-container {
  flex-wrap: wrap;
  align-content: flex-start; /* wrap */
}

.summary-box-margin {
  margin: 5px 30px 5px 30px;
}

.center-alignment-both-axis {
  justify-content: center;
  align-items: center;
}

.center-alignment-main-axis {
  justify-content: center;
}

.center-alignment-cross-axis {
  align-items: center;
}

.site-box {
  min-width: 20%;
  margin: 10px 0px 10px 20px;
  padding: 10px;
  gap: 10px;
  flex-shrink: 0;

  border: 1px solid rgb(241, 237, 237);
  border-radius: 12px;

  transition: box-shadow 0.3s ease-in-out;
}

.site-box:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

#site-container {
  height: 100%;
  /* height: 60vh; */
  /* overflow-y: scroll; */
  padding-bottom: 0px;
}

#status-label {
  font-weight: bold;
  margin-left: 30px;
  margin-right: 20px;
}

#status-selection-bar {
  margin-top: 10px;
  margin-bottom: 10px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.label-value-display-row {
  width: 100%;
  display: flex;
  justify-content: space-between;

  padding: 2px 0;
}

.label-value-unit-display-row {
  width: 100%;
  display: flex;
  justify-content: space-between;

  font-weight: bold;
}

.label-value-unit-display-label {
  @media (width<460px) {
    /* wrap label when screen size is small*/
    max-width: 250px;
  }
}
